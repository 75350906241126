import anchor from './anchor.module.scss'
import black from './black.module.scss'
import coalMineCanary from './coalMineCanary.module.scss'
import cyanora from './cyanora.module.scss'
import futureFuscia from './futureFuscia.module.scss'
import gray from './gray.module.scss'
import primary from './primary.module.scss'
import secondary from './secondary.module.scss'
import turquoise from './turquoise.module.scss'
import white from './white.module.scss'

interface Preset {
    medium?: string
    small?: string
    playing?: string
    complete?: string
    button?: string
}

export const PRESETS = {
    anchor: anchor as Preset,
    primary: primary as Preset,
    secondary: secondary as Preset,
    plain: {} as Preset,
    black: black as Preset,
    white: white as Preset,
    gray: gray as Preset,
    futureFuscia: futureFuscia as Preset,
    cyanora: cyanora as Preset,
    coalMineCanary: coalMineCanary as Preset,
    turquoise: turquoise as Preset,
}
